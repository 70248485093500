import React from "react";
import { Row, Col, Container, ListGroup, Image } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import homepage_quickres from "../thnkdev_images/homepage_quickres.png";
import homepage_fire from "../thnkdev_images/homepage_fire.png";
import homepage_quicklock from "../thnkdev_images/homepage_quicklock.png";
import homepage_quickpark from "../thnkdev_images/homepage_quickpark.png";
import homepage_screenie from "../thnkdev_images/homepage_screenie.png";
import homepage_snapped from "../thnkdev_images/homepage_snapped.png";
import homepage_nikola from "../thnkdev_images/homepage_nikola.png";
import homepage_pearl from "../thnkdev_images/homepage_pearl.png";

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "thnkdev" }}>
    <SEO title="Home" keywords={['quickres', 'screenie', 'thnkdev']}/>
    <Container className="homepageImageWrapper">
      <div class="appImageWrapper">
        <a href="/Screenie">
          <Image src={homepage_screenie} fluid />
        </a>
        Screenie is the ultimate menubar image manager
      </div>

      <div class="appImageWrapper">
        <a href="/QuickRes">
          <Image src={homepage_quickres} fluid />
        </a>
        QuickRes is the best way to switch between screen resolutions on your
        Mac
      </div>

      <div class="appImageWrapper">
        <a href="/QuickPark">
          <Image src={homepage_quickpark} fluid />
        </a>
        QuickPark is your personal parking assistant, both on your wrist and in
        your pocket
      </div>

      <div class="appImageWrapper">
        <a href="https://apps.apple.com/us/app/nikola/id1412473099?mt=12">
          <Image src={homepage_nikola} fluid />
        </a>
        Nikola is a Tesla owners dream, a helpful menubar app to control your
        Tesla
      </div>

      <div class="appImageWrapper">
        <a href="https://apps.apple.com/us/app/pearl-mirror-in-your-menubar/id1152715357?mt=12">
          <Image src={homepage_pearl} fluid />
        </a>
        Pearl gives you a mirror whenever you need it
      </div>

      <div class="appImageWrapper">
        <a href="https://www.producthunt.com/posts/fire-for-tinder-2">
          <Image src={homepage_fire} fluid />
        </a>
        Fire for Tinder was the first Tinder client for Apple TV
      </div>

      <div class="appImageWrapper">
        <a href="https://mashable.com/2014/01/21/snapped-snapchat-app/">
          <Image src={homepage_snapped} fluid />
        </a>
        Snapped was the first native Snapchat client for macOS
      </div>

      <div class="appImageWrapper">
        <a href="https://lifehacker.com/quicklock-is-a-better-way-to-lock-your-mac-5957546">
          <Image src={homepage_quicklock} fluid />
        </a>
        QuickLock was a lightweight, customizable lockscreen for your Mac
      </div>
    </Container>
  </Layout>
);

export default IndexPage;
